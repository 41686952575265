import { IResourceComponentsProps, useCustom, useGetIdentity, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
import { UploadProps } from "antd/es/upload";
import { Avatar, Badge, Button, Col, ConfigProvider, Divider, Form, Row, Spin, Upload, message } from "antd";
import { useRef, useState } from "react";
import { CameraOutlined, LoadingOutlined, UserOutlined, ApartmentOutlined, DeleteOutlined, DollarOutlined, ShopOutlined, LogoutOutlined, EyeOutlined, IdcardOutlined, BankOutlined, TrophyOutlined, StarOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { API_URL, DOMAIN_URL } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { MenuCustom } from "components/menu_footer";
// import axios from "axios";

let defaultPix: any = "https://i.pinimg.com/736x/62/2e/d0/622ed0721f44cd4cd69232b3403aaf98.jpg"
export const Profile: React.FC<IResourceComponentsProps> = () => {
    
    const t = useTranslate();
    const { push } = useNavigation();
    const refUpload = useRef<any>();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { mutate: mutateLogout } = useLogout();

    const [userProfile, setuserProfile] = useState<any>();
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [pixURL, setpixURL] = useState<any>();
    const [themeCurrent, setthemeCurrent] = useState();
    const [point, setpoint] = useState<any>();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);
                setthemeCurrent((pre: any) => dataLoad?.user_profile?.current_theme || "styles-main");
                if(dataLoad?.user_profile?.line_pictureUrl){
                    setpixURL(dataLoad?.user_profile?.line_pictureUrl);
                    setswpixList(false);
                }else{
                    setpixURL(undefined);
                    setswpixList(true);
                }

                settk(!tk);
                console.log(">>> dataLoad", dataLoad)
                await getPoint(dataLoad?.user_profile?.id);
                // await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    async function getPoint(id: any){
        axiosInstance.get(`${API_URL}/api/user-point/find_point_by_profile?id=${id}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data?.point;
            setpoint((pre: any) => response.toFixed(2));
            settk(!tk);
        })
        .catch(error => {

        });
    }

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        document.head.appendChild(link);
    }

    const props: UploadProps = {
        name: "files",
        listType: "picture",
        accept: ".png,.jpeg,.jpg",
        multiple: false,
        maxCount: 1,
        showUploadList: false,
        openFileDialogOnClick: false,
        action: `${API_URL}/api/upload`,
        headers: {
            Authorization: `Bearer ${TOKEN}`,
        },
        beforeUpload: (file) => {
            const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
            if (!validateSize) {
              message.error(`file size is over 5mb.`);
            }
            return validateSize || Upload.LIST_IGNORE;
        },
        onChange: (res) => {
            if(res?.file?.status == "done"){
                let respons: any = res?.file?.response[0];
                changepixUrl(respons?.url, respons)
            }
        }
    };

    const theme: any = [
        {
            id: 1,
            name: "Default",
            value: "styles-main",
            color: "#F07D51",
            status: "allow"
        },
        {
            id: 2,
            name: "Abstract",
            value: "styles-abstract",
            color: "#C3A6EE",
            status: "allow"
        },
        {
            id: 3,
            name: "Cyber",
            value: "styles-cyber",
            color: "#FFFFF",
            status: "allow"
        },
        {
            id: 4,
            name: "Natural",
            value: "styles-natural",
            color: "#BABABA",
            status: "disable"
        }
    ]

    const renderTheme: any = (theme: any) => {
        return (
            <Col span={12} className="gutter-row">
                <div
                    className={`${themeCurrent == theme?.value ? "theme-panel-items-checked" : "theme-panel-items"}`}
                    style={{
                        backgroundColor: "#BABABA", 
                    }}
                    onClick={() => {if(theme?.status == "allow"){bodyTheme(theme?.value)}}}
                >
                    <div style={{color: "#fff"}}>{theme?.name}</div>
                </div>
            </Col>
        )
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setswpixList(false)
        setTimeout(() => {
            setLoading(true);
            bodyProfile(url, "add")
        }, 300);
    }

    function removepixList() {
        setpixURL("");
        setswpixList(true);
        setTimeout(() => {
            setLoading(true);
            bodyProfile("", "del")
        }, 300);
    }

    function bodyProfile(value: any, mode: "add" | "del"){
        let body: any;
        if(mode == "add"){body = {line_pictureUrl: value}}else if(mode == "del"){body = {line_pictureUrl: undefined}}

        setTimeout(() => {
            updateProfile(body);
        }, 300);
    }

    function bodyTheme(value: any){
        let body: any = {current_theme: value};
        setthemeCurrent((pre: any) => value);
        settk(!tk);
        setTimeout(() => {
            setLoading(true);
            updateProfile(body);
            getTheme(value);
        }, 300);
    }

    function updateProfile(body: any){
        axiosInstance.put(`${API_URL}/api/user-profile/update_profile?id=${userProfile?.id}`, body, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            window?.location?.reload();
        })
        .catch(error => {
            console.error('error ',error);
        });
    }
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                indicator={<div className="spin-loading-site" />}
                fullscreen={isLoading}
            >
                <div className="layout-page">
                    <div id="body-containers" className="body-layout">
                        <div className="body-container-page">
                            <div className="panel-header" style={{height: '13dvh', padding: '0 30px', display: 'grid', justifyContent: 'start', alignItems: 'center', gridTemplateColumns: '70% 30%'}}>
                                <div><img style={{width: "50px"}} src={`${DOMAIN_URL}/images/svg/i24-logo.svg`} alt="logo"/></div>
                                <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}><Button icon={<LogoutOutlined/>} type="primary" onClick={() => mutateLogout()} style={{width: "30px", height: "30px", backgroundColor: '#a4283a', boxShadow: "1px 0px 10px #e31c39", marginTop: '-10px'}}/></div>
                            </div>
                            <div className="panel-body" style={{alignItems: "center", height: "79dvh"}}>
                                <div style={{width: "100%", padding: '0 30px', maxHeight: '80dvh', overflowY: 'auto', marginBottom: 40}}>
                                    <div className="panel-txt-header">
                                        <div style={{color: "#fff", fontSize: 20, marginBottom: 10}}>{'โปรไฟล์'}</div>
                                    </div>
                                    <div style={{display: "grid", gap: "16px",gridTemplateColumns: "65% 35%" , justifyContent: "center", alignItems: "center", marginBottom: 10, padding: '0 8px'}}>
                                        <div className="box_border_running blue" style={{width: "100%", height: "auto", borderRadius: 5, backdropFilter: 'blur(20px)'}}>
                                            <Form.Item
                                                name="avatar"
                                                className="form_projectPix"
                                                style={{marginBottom: 0}}
                                            >
                                                <Dragger className="dragger_upload_custom_i_w"
                                                    {...props}
                                                    ref={refUpload}
                                                    style={{border: 0}}
                                                >
                                                    <div className="dragger_upload_custom_i_h" style={{}}>
                                                        <div className="projectPix_hover">
                                                            <div>
                                                                {swpixList == false ?
                                                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "15px"}}>
                                                                        <CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                        <DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                                    </div>
                                                                    :
                                                                    <Row gutter={16}>
                                                                        <Col span={5} className="gutter-row" />
                                                                        <Col span={14} className="gutter-row">
                                                                            <CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                        </Col>
                                                                        <Col span={5} className="gutter-row" />
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <Avatar className="projectPix" src={pixURL} shape="square" style={{borderRadius: '5px 5px 0 0'}}/> */}
                                                        <div style={{position: "absolute", background: "linear-gradient(0deg, rgb(244, 159, 0) 0%, rgba(245,255,0,1) 100%)", padding: "0 10px", borderRadius: "5px 0 10px 0", color: "#fff", textShadow: "1px 2px 2px rgba(0,0,0,0.2)"}}>{"VIP+"}</div>
                                                        <img style={{width: "100%", height: "100%", borderRadius: '5px 5px 0 0', objectFit: "cover", objectPosition: "center"}} src={pixURL ? pixURL: defaultPix} />
                                                    </div>
                                                </Dragger>
                                            </Form.Item>
                                            <div style={{color: "#fff", paddingLeft: 10, background: 'linear-gradient(80deg, rgba(45,104,165,1) 0%, rgba(255,255,255,0) 100%)', borderRadius: '0 0 5px 5px'}}>{userProfile?.nick_name ? userProfile?.nick_name: "#wait popup nickname"}</div>
                                        </div>
                                        <div style={{display: "grid", gap: "16px",gridTemplateColumns: "100%", height: '100%'}}>
                                            <div className="panel-detail">
                                                <div style={{width: "100%", height: '100%', display: 'flex', alignItems: 'center'}}>
                                                    <div style={{padding: 15}}>
                                                        <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"Point"}</div>
                                                        <div style={{fontSize: 18 ,color: "#66b2ff", fontWeight: "bold", textShadow: "0px 1px 5px rgba(70, 162, 255, 0.4)", letterSpacing: 2}}>{t(`${point}`)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-detail">
                                                <div style={{width: "100%", height: 'auto', display: 'flex', alignItems: 'center'}}>
                                                    <div style={{padding: 15}}>
                                                        <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"ลางานแล้ว"}</div>
                                                        <div style={{fontSize: 18 ,color: "#66b2ff", fontWeight: "bold", textShadow: "0px 1px 5px rgba(70, 162, 255, 0.4)", letterSpacing: 2}}>{t(`0 ครั้ง`)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: "grid", gap: "16px",gridTemplateColumns: "100%", height: '100%'}}>
                                        <div style={{width: "100%", height: "100%", borderRadius: 5}}>
                                            <div className="panel-detail">
                                                <div style={{padding: 15}}>
                                                    <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"Emp No."}</div>
                                                    <div style={{marginBottom: 10, fontSize: "14px"}}>
                                                        <span style={{marginRight: "5px"}}>{userProfile?.emp_no}</span>
                                                    </div>
                                                    <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"First-name / Last-name"}</div>
                                                    <div style={{marginBottom: 10, fontSize: "14px"}}>
                                                        <span style={{marginRight: "5px"}}>{userProfile?.first_name}</span>
                                                        <span>{userProfile?.last_name}</span>
                                                    </div>
                                                    <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"Company"}</div>
                                                    <div style={{marginBottom: 10, fontSize: "14px"}}>
                                                        <span style={{marginRight: "5px"}}>{userProfile?.companies[0]?.company_name}</span>
                                                    </div>
                                                    <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"Position"}</div>
                                                    <div style={{marginBottom: 10, fontSize: "14px"}}>
                                                        <span style={{marginRight: "5px"}}>{userProfile?.position?.pos_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="panel-footer" style={{marginBottom: '30px'}}>
                                        <Button icon={<ShopOutlined />} className="btn-theme" type="primary" style={{width: "100%", height: "40px", marginBottom: "10px"}} onClick={() => push("/market")}>{t("Edit Nickname")}</Button>
                                        <Button icon={<LogoutOutlined/>} type="primary" onClick={() => mutateLogout()} style={{width: "100%", height: "50px", backgroundColor: '#46A2FF', boxShadow: "1px 0px 10px #5517ff"}}>{t("Logout")}</Button>
                                    </div> */}
                                    {/* <div style={{display: "grid", gap: "16px",gridTemplateColumns: "30% 30% 30%" , justifyContent: "center", alignItems: "center"}}>
                                        <div className="header" style={{width: "100%", height: "100%"}}>
                                            <Form.Item
                                                name="avatar"
                                                className="form_projectPix"
                                            >
                                                <Dragger className="dragger_upload_custom_i_w"
                                                    {...props}
                                                    ref={refUpload}
                                                    style={{border: 0}}
                                                >
                                                    <div className="dragger_upload_custom_i_h" style={{}}>
                                                        <div className="projectPix_hover">
                                                            <div>
                                                                {swpixList == false ?
                                                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "15px"}}>
                                                                        <CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                        <DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                                    </div>
                                                                    :
                                                                    <Row gutter={16}>
                                                                        <Col span={5} className="gutter-row" />
                                                                        <Col span={14} className="gutter-row">
                                                                            <CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                        </Col>
                                                                        <Col span={5} className="gutter-row" />
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </div>
                                                        <Avatar className="projectPix" src={pixURL} shape="square"/>
                                                    </div>
                                                </Dragger>
                                            </Form.Item>
                                        </div>
                                        <div className="fix-ribbon" style={{width: "100%", height: "100%"}}>
                                            <Badge.Ribbon text={<div><IdcardOutlined style={{marginRight: "5px"}}/>{"License"}</div>} className="ribbon-color-custom">
                                                <div className="panel-detail">
                                                    <div style={{width: "100%"}}>
                                                        <div className="txt-point" style={{textAlign: "center"}}><EyeOutlined onClick={() => push("/profile/card-id")}/></div>
                                                    </div>
                                                </div>
                                            </Badge.Ribbon>
                                        </div>
                                        <div className="fix-ribbon" style={{width: "100%", height: "100%"}}>
                                            <Badge.Ribbon text={<div><DollarOutlined style={{marginRight: "5px"}}/>{"Point"}</div>} className="ribbon-color-custom">
                                                <div className="panel-detail">
                                                    <div style={{width: "100%"}}>
                                                        <div className="txt-point" style={{textAlign: "center"}}>{t(`${point}`)}</div>
                                                    </div>
                                                </div>
                                            </Badge.Ribbon>
                                        </div>
                                    </div>
                                    <Badge.Ribbon text={<div><UserOutlined style={{marginRight: "5px"}}/>{"Profile Details"}</div>} placement="start" className="ribbon-color-custom">
                                        <div className="panel-name">
                                            <div style={{marginTop: "1rem"}}>
                                                <div style={{color: "#fff", fontSize: "12px", opacity: "0.5"}}>{"Emp No."}</div>
                                                <div>
                                                    <span style={{marginRight: "5px"}}>{userProfile?.emp_no}</span>
                                                </div>
                                                <div style={{color: "#fff", fontSize: "12px", opacity: "0.5"}}>{"First-name / Last-name"}</div>
                                                <div>
                                                    <span style={{marginRight: "5px"}}>{userProfile?.prefix}</span>
                                                    <span style={{marginRight: "5px"}}>{userProfile?.first_name}</span>
                                                    <span>{userProfile?.last_name}</span>
                                                </div>
                                                <div style={{color: "#fff", fontSize: "12px", opacity: "0.5"}}>{"Company"}</div>
                                                <div>
                                                    <span style={{marginRight: "5px"}}>{userProfile?.companies[0]?.company_name}</span>
                                                </div>
                                                <div style={{color: "#fff", fontSize: "12px", opacity: "0.5"}}>{"Position"}</div>
                                                <div>
                                                    <span style={{marginRight: "5px"}}>{userProfile?.position?.pos_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Badge.Ribbon>
                                    
                                    <div style={{display: "grid", gridTemplateColumns: "50% 50%", gap: "10px"}}>
                                        <div>
                                            <Button icon={<DollarOutlined />} className="btn-theme" type="primary" style={{width: "100%", height: "40px", marginBottom: "10px", marginTop: "20px"}} onClick={() => push("/score-board")}>{t("Score Board")}</Button>
                                        </div>
                                        <div>
                                            <Button icon={<DollarOutlined />} className="btn-theme" type="primary" style={{width: "100%", height: "40px", marginBottom: "10px", marginTop: "20px"}} onClick={() => push("/market")} disabled>{t("Daily Quest")}</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}