import { useNavigation } from "@refinedev/core";
import "./styles.css";
import { HomeOutlined, HistoryOutlined, ShopOutlined, UserOutlined, AuditOutlined, UnorderedListOutlined  } from '@ant-design/icons';
import { DOMAIN_URL } from "../../constants";

export const MenuCustom = () => {

  const { push } = useNavigation();
  
  return (
    <div className="menu-custom-grid">
      <div className="item-menu"><div onClick={() => push("/leave-work")}><AuditOutlined style={{fontSize: "18px", cursor: "pointer"}}/><div>{"ลางาน"}</div></div></div>
      <div className="item-menu"><div onClick={() => push("/market")}><ShopOutlined style={{fontSize: "18px", cursor: "pointer"}}/><div>{"ร้านค้า"}</div></div></div>
      <div className="item-menu item-center">
        <div className="panel-back">
          <div className="panel-btn" onClick={()=> window.location.href = `${DOMAIN_URL}/check-in`}>
            <HomeOutlined style={{fontSize: "38px",color: '#ffff'}}/>
          </div>
        </div>
      </div>
      <div className="item-menu"><div onClick={() => push("/history")}><UnorderedListOutlined style={{fontSize: "18px", cursor: "pointer"}}/><div>{"ประวัติ"}</div></div></div>
      <div className="item-menu"><div onClick={() => push("/profile")}><UserOutlined style={{fontSize: "18px", cursor: "pointer"}}/><div>{"โปรไฟล์"}</div></div></div>
    </div>
  );
};