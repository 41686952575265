import { IResourceComponentsProps, useCustom, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
// import "./script.js";
import { Col, Row, Spin, ConfigProvider, Avatar, Badge, notification, Button, Drawer } from "antd";
import { API_URL, DOMAIN_URL } from "../../constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "@refinedev/strapi-v4";
import dayjs from "dayjs";
import { HistoryOutlined, LoadingOutlined, NotificationOutlined, StarOutlined, TrophyOutlined, SmileOutlined, LogoutOutlined, BellOutlined, AuditOutlined, CrownOutlined } from '@ant-design/icons';
import { MenuCustom } from "components/menu_footer";
import { AdminPage } from "pages/admin";

let nowdate: any = new Date();
let defaultPix: any = "https://i.pinimg.com/736x/62/2e/d0/622ed0721f44cd4cd69232b3403aaf98.jpg"
export const CheckIN: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { push } = useNavigation();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [crtime, setcrtime] = useState<any>();
    const [timestamp, settimestamp] = useState<any>();
    const [statusstamp, setstatusstamp] = useState<any>();
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { mutate: mutateLogout } = useLogout();

    const [userPermission, setuserPermission] = useState<any>();
    const [userProfile, setuserProfile] = useState<any>();
    const [themeCurrent, setthemeCurrent] = useState();
    const [point, setpoint] = useState<any>();
    const [dayToday, setdayToday] = useState<any>();
    const [admintools, setadmintools] = useState<boolean>(false);
    const [disableCheck, setdisableCheck] = useState<boolean>(false);

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);
                setuserPermission((pre: any) => dataLoad?.user_type);

                settk(!tk);
                await getPoint(dataLoad?.user_profile?.id);
                // await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                setTimeout(() => {
                    getTime();
                    getToday();
                }, 300);

            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });


    function getTime(){
        axiosInstance.get(`${API_URL}/api/attendances/curtime2`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            let respon: any = res?.data;
            setcrtime((pre: any) => respon);
            settk(!tk);
            setTimeout(() => {
                getstamp();
            }, 300);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    // async function getTheme(value: any){
    //     let theme: any = value;
    //     const link = document.createElement('link');
    //     link.rel = 'stylesheet';
    //     link.href = `${DOMAIN_URL}/theme/${theme}.css`;
    //     // link.href = `${DOMAIN_URL}/theme/styles-space-x.css`;
    //     document.head.appendChild(link);
    // }

    function getstamp(){
        axiosInstance.get(`${API_URL}/api/attendances/last_time`,
        { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((response) => {
            let res = response?.data;
            settimestamp((pre: any) => res);
            settk(!tk);

            setTimeout(() => {
                setLoading(false);
                if(userProfile?.id){
                    getPoint(userProfile?.id);
                }
            }, 300);
        })
        .catch(error => {

        });
    }

    function getToday(){
        let today: any = dayjs(new Date)?.format("dddd");
        setdayToday(today);
        if(today?.toLowerCase() == "saturday" || today?.toLowerCase() == "sunday"){
            setdisableCheck(true);
        }
        settk(!tk);
    }

    async function getPoint(id: any){
        axiosInstance.get(`${API_URL}/api/user-point/find_point_by_profile?id=${id}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data?.point;
            setpoint((pre: any) => response.toFixed(2));
            settk(!tk);
        })
        .catch(error => {

        });
    }

    function checked(){
        axiosInstance.get(`${API_URL}/api/clock/ez_clock`,
        { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((response) => {
            let res = response?.data;
            setstatusstamp((pre: any) => res);
            setLoading(true);
            settk(!tk);
            setTimeout(() => {
                getTime();
            }, 300);
        })
        .catch(error => {

        });
    }

    // const aa = () => {
    //     let hrElement;
    //     let width = 450;
    //     let counter = 20;
    //     let container = document.getElementById(`fx-panel`);
    //     if(container){

    //         for (let i = 0; i < counter; i++) {
    //           hrElement = document.createElement("HR");
    //           if (i == counter - 1) {
    //             hrElement.className = "thunder";
    //           } else {
    //             hrElement.style.left = Math.floor(Math.random() * width) + "px";
    //             hrElement.style.animationDuration = 1 + Math.random() * 1 + "s";
    //             hrElement.style.animationDelay = Math.random() * 5 + "s";
    //           }
            

    //           hrElement.innerHTML = "";
    //           container.appendChild(hrElement);

    //         //   container.(hrElement);
    //         //   document.body.appendChild(hrElement);
    //         }
    //     }
    // }

    // useEffect(() => {
    //  aa()
    // }, [isLoading])

    const renderDailyday: any = () => {
        let daylist: any = [
            {id: 0,label: "monday",label_th: "จันทร์"},
            {id: 1,label: "tuesday",label_th: "อังคาร"},
            {id: 2,label: "wednesday",label_th: "พุธ"},
            {id: 3,label: "thursday",label_th: "พฤหัส"},
            {id: 4,label: "friday",label_th: "ศุกร์"}
        ]

        return daylist?.map((item: any) => {
            return (
                <div 
                    id={item?.label} 
                    style={{
                        width: "100%", 
                        height: "auto", 
                        borderRadius: 10, 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        color: "#fff",
                        padding: '10px 0',
                        background: dayToday?.toLowerCase() == item?.label ? "#46A2FF" :"#373737", 
                        boxShadow: dayToday?.toLowerCase() == item?.label ? "0px 0px 5px 2px rgb(23, 73, 255)" :"none", 
                    }}
                >
                    <div>
                        <div>{item?.label_th}</div>
                        <div style={{textAlign: "center", marginTop: 5}}><SmileOutlined style={{fontSize: 20}}/></div>
                    </div>
                </div>
            )
        })
    }
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                indicator={<div className="spin-loading-site" />}
                fullscreen={isLoading}
            >
                <div className="layout-check-in">
                    <div id="body-containers" className="body-container-check-in">
                        <div className="body-panel-check-in" style={{borderRadius: "0 0 30px 30px"}}>
                            <div className="panel-header" style={{height: '13dvh', padding: '0 30px', display: 'grid', justifyContent: 'start', alignItems: 'center', gridTemplateColumns: '30% 70%'}}>
                                <div><img style={{width: "50px"}} src={`${DOMAIN_URL}/images/svg/i24-logo.svg`} alt="logo"/></div>
                                <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 10, height: '100%'}}>
                                    {(userPermission?.type_name == "Admin" || userPermission?.type_name == "Super Admin") &&
                                        <div className="box_border_running yellow" style={{borderRadius: 10, marginTop: '-10px'}}>
                                            <Button icon={<CrownOutlined style={{color: "#ffb92c"}}/>} type="primary" style={{width: "auto", height: "30px", background: 'linear-gradient(33deg, rgba(255,176,15,1) 0%, rgba(40,55,84,1) 36%)'}} onClick={()=> setadmintools(true)}><span style={{color: "#fff"}}>{'Admin Tools'}</span></Button>
                                        </div>
                                    }
                                    <Button icon={<AuditOutlined/>} type="primary" style={{width: "30px", height: "30px", backgroundColor: '#283754', marginTop: '-10px'}}/>
                                    <Badge dot={true}><Button icon={<BellOutlined/>} type="primary" style={{width: "30px", height: "30px", backgroundColor: '#283754', marginTop: '-10px'}}/></Badge>
                                </div>
                            </div>
                            <div className="panel-body" style={{alignItems: "end", height: "61.9dvh"}}>
                                <div>
                                    <div style={{padding: "0 30px", marginBottom: "40px"}}>
                                        <div className="panel-blur" style={{marginBottom: "15px"}}>
                                            <div style={{display: "flex", gap: "5px", color: "#fff", justifyContent: "center", alignItems: "center", fontSize: "14px"}}>
                                                <span>{dayjs(nowdate).format("DD/MM/YYYY")}</span>
                                                <span>{crtime}</span>
                                            </div>
                                        </div>

                                        <div style={{display: "grid", gap: "20px", gridTemplateColumns: "57% 38%", marginBottom: "20px"}}>
                                            <div style={{border: "1px solid #46A2FF", borderRadius: 10, backdropFilter: "blur(20px)", background: "linear-gradient(140deg, rgba(11,198,255,0.1) 40%, rgba(0,0,0,0) 100%)"}}>
                                                <div className="wrapper">
                                                    <div style={{position: "relative", zIndex: 5, padding: "15px"}}>
                                                        <div>
                                                            <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{t("เวลาเข้างาน")}</div>
                                                            <div style={{fontSize: 16 ,color: "#66b2ff", fontWeight: "bold", textShadow: timestamp?.time_in !== '-:-' ? "0px 1px 5px rgba(70, 162, 255, 0.4)" : "none", letterSpacing: 2}}>{timestamp?.time_in !== '-:-' && timestamp?.time_in !== '-' ? timestamp?.time_in : "--"}</div>
                                                        </div>
                                                        <div>
                                                            <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{t("เวลาออกงาน")}</div>
                                                            <div style={{fontSize: 16 ,color: "#66b2ff", fontWeight: "bold", textShadow: timestamp?.time_out !== '-:-' ? "0px 1px 5px rgba(70, 162, 255, 0.4)" : "none", letterSpacing: 2}}>{timestamp?.time_out !== '-:-' && timestamp?.time_out !== '-'  ? timestamp?.time_out : "--"}</div>
                                                        </div>
                                                        <div>
                                                            <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{t("เวลาทำงาน")}</div>
                                                            <div style={{fontSize: 16 ,color: "#66b2ff", fontWeight: "bold", textShadow: timestamp?.time_summary !== '-:-' ? "0px 1px 5px rgba(70, 162, 255, 0.4)" : "none", letterSpacing: 2}}>{timestamp?.time_summary !== '-:-' && timestamp?.time_summary !== '-' ? timestamp?.time_summary : "--"}</div>
                                                        </div>
                                                    </div>
                                                    <div className="border_blue"/>
                                                </div>
                                            </div>
                                            <div className="box_border_running yellow" style={{borderRadius: 10}}>
                                                <div style={{position: "absolute", background: "linear-gradient(0deg, rgb(244, 159, 0) 0%, rgba(245,255,0,1) 100%)", padding: "0 10px", borderRadius: "5px 0 10px 0", color: "#fff", textShadow: "1px 2px 2px rgba(0,0,0,0.2)"}}>{"VIP+"}</div>
                                                <img style={{width: "100%", height: "100%", borderRadius: 5, objectFit: "cover", objectPosition: "center"}} src={userProfile?.line_pictureUrl ? userProfile?.line_pictureUrl:defaultPix} />
                                            </div>
                                        </div>

                                        <div style={{display: "grid", gap: "20px", gridTemplateColumns: "35% 60%", marginBottom: "20px"}}>
                                            <div style={{padding: "10px 15px", border: "1px solid #46A2FF", borderRadius: 10, backdropFilter: "blur(20px)", background: "linear-gradient(140deg, rgba(11,198,255,0.1) 40%, rgba(0,0,0,0) 100%)"}}>
                                                <div style={{color: "#fff", fontSize: "10px", opacity: "0.6"}}>{"Point"}</div>
                                                <div style={{color: "#66b2ff", fontWeight: "bold", textShadow: "0px 1px 5px rgba(70, 162, 255, 0.4)", letterSpacing: 2, fontSize: 22}}>{t(`${point}`)}</div>
                                            </div>
                                            <div 
                                                style={{border: "1px solid #ffc917",boxShadow: "1px 0px 10px rgba(255, 193, 23, 0.5)", borderRadius: 10, backdropFilter: "blur(20px)", background: "linear-gradient(0deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 100%)", cursor: "pointer"}} 
                                                onClick={()=> push("/score-board")}
                                            >
                                                <div className="wrapper" style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <div style={{position: "relative", zIndex: 5, padding: "15px"}}>
                                                        <TrophyOutlined style={{marginRight: 10, fontSize: "24px", color: "#ffb92c", textShadow: "1px 2px 2px rgba(0,0,0,0.6)"}}/><span style={{color: "#565656", fontSize: 18, letterSpacing: 1}}>{"Score Board"}</span>
                                                        <div style={{color: "#565656", fontSize: "10px", opacity: "0.6"}}>{'วัดระดับการทำงานของทีมด้วย Point'}</div>
                                                    </div>
                                                    <div className="border_yellow"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div 
                                            style={{
                                                display: "flex", 
                                                justifyContent: "center", 
                                                alignItems: "center", 
                                                padding: "15px 0", 
                                                background: !disableCheck ? "linear-gradient(270deg, rgba(47,215,254,1) 0%, rgba(83,55,255,1) 50%, rgba(252,51,255,1) 100%)" : "#4e4e4e", 
                                                borderRadius: 10, 
                                                cursor: !disableCheck ? "pointer" : 'not-allowed', 
                                                boxShadow: !disableCheck ? 'rgb(124, 203, 255, 0.3) 50px 1px 20px inset, rgb(228, 248, 255) 0px 0px 0px 1px inset' : 'none'
                                            }}
                                            onClick={() => {if(disableCheck == false){checked()}}}
                                        >
                                            <div>
                                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>
                                                    <div className="circle-check-in-last" style={{display: !disableCheck ? 'contents': 'none'}}>
                                                        <div className="circle-check-in-middle">
                                                            <div className={`${timestamp?.time_in == '-:-' ? 'circle-check-in-first' : 'circle-check-out-first' }`} onClick={() => checked()}>
                                                                <HistoryOutlined style={{fontSize: "26px", color: timestamp?.time_in == '-:-' ? '#80bfff' : '#fff'}}/>
                                                            </div>
                                                            <div className="shadow-circle"></div>
                                                        </div>
                                                    </div>
                                                    <div style={{color: "#fff", fontSize: "22px", fontWeight: "bold", letterSpacing: 1}}>{!disableCheck ? timestamp?.time_in == '-:-' ? "Clock In" : "Clock Out" : "วันหยุดค้าบ"}</div>
                                                </div>
                                                <div style={{color: "#fff", fontSize: "10px", opacity: "0.6", display: !disableCheck ? 'contents': 'none'}}>{timestamp?.time_in == '-:-' ? 'สวัสดีตอนเช้านะ กินกาแฟมั้ยตาจะได้สว่าง':'เลิกงานแล้ว เดินทางกลับบ้านอย่างปลอดภัยนะ'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{background: "#000", height: "14.9dvh", padding: "0px 20px 20px 20px"}}>
                            <div style={{display: "grid", gridTemplateColumns: "17% 17% 17% 17% 17%", gap: "13px", justifyContent: "center", alignItems: "center", height: "100%"}}>
                                {renderDailyday()}
                            </div>
                        </div>
                        
                        {/* <div id="fx-panel"></div> */}

                        <div className="panel-menu"><MenuCustom/></div>

                        <Drawer
                            title={
                                <div style={{marginTop: 3, display: 'grid', justifyContent: 'start', alignItems: 'center', gridTemplateColumns: '50% 50%'}}>
                                    <div>
                                        <CrownOutlined style={{color: "#ffb92c", marginRight: 10, fontSize: 18}}/>
                                        <span style={{color: "#fff", fontWeight: 200}}>{'Admin Tools'}</span>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 10, height: '100%'}}>
                                        <button style={{background: 'transparent', border: 'none', fontSize: 16, color: '#8f8f8f'}} onClick={()=> setadmintools(false)}>X</button>
                                    </div>
                                </div>
                            }
                            placement="right"
                            closable={false}
                            onClose={() => setadmintools(false)}
                            open={admintools}
                            getContainer={false}
                            width={500}
                        >
                            <AdminPage/>
                        </Drawer>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}