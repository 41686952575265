import { IResourceComponentsProps, useCustom, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import { Spin, ConfigProvider, Table, Tag, DatePicker, Row, Col, Select, Form, Input, Button, Radio, message } from "antd";
import { API_URL, DOMAIN_URL } from "../../constants";
import { useState } from "react";
import { axiosInstance } from "@refinedev/strapi-v4";
import dayjs from "dayjs";
import { LoadingOutlined } from '@ant-design/icons';
import { MenuCustom } from "components/menu_footer";
import "./styles.css";
import { RangePickerProps } from "antd/es/date-picker";

export const LeaveWorkPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TextArea } = Input;
    const { RangePicker } = DatePicker;
    const { push } = useNavigation();
    
    const TOKEN: any = localStorage.getItem("AUTH");
    const USER: any = localStorage.getItem("USER");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [userProfile, setuserProfile] = useState<any>();
    const [form] = Form.useForm<any>();
    const { mutate: mutateLogout } = useLogout();

    ///// state display ////////////////////////////////////

    const [allowLeave, setallowLeave] = useState<boolean>(false);
    const [leaveType, setleaveType] = useState<any>();
    const [canLeaveCount, setcanLeaveCount] = useState<any>(0);

    ///// state value ////////////////////////////////////

    const [valLeave, setvalLeave] = useState<any>(); //data ประเภทการลา
    const [leaveSub, setleaveSub] = useState("one"); //data รูปแบบการลา
    const [leaveMode, setleaveMode] = useState("Full"); //data ช่วงเวลา

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                setuserProfile((pre: any) => dataLoad?.user_profile);
                // setthemeCurrent((pre: any) => dataLoad?.user_profile?.current_theme || "styles-main");

                settk(!tk);
                // await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                await getLeavetype();
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return valLeave == 3 && dayjs().add(14, 'days')  >= current;
    };

    async function getLeavetype(){
        axiosInstance.get(`${API_URL}/api/leave-types/findAll`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data;
            let options: any = [];

            for (let index = 0; index < response?.length; index++) {
                options.push({
                    label: response[index]?.attributes?.leave_type_name,
                    value: response[index]?.id
                })
            }
            setleaveType((pre: any) => options)
            settk(!tk);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch(error => {

        });
    }

    async function checkLeave(id: any){
        axiosInstance.get(`${API_URL}/api/leaves/findYourLeave?leave_type_id=${id}&user_id=${USER}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data;
            if(response?.can_leave == true){
                setvalLeave(id);
                setcanLeaveCount(response?.leave_left);
                setallowLeave(true);
                settk(!tk);
            }else if(response?.can_leave == false){
                setvalLeave(undefined);
                setcanLeaveCount(0);
                setallowLeave(false);
                settk(!tk);
                message.error(`คุณไม่เหลือวันลาแล้ว กรุณาติดต่อหัวหน้าของคุณ`);
                form.resetFields(["leave_type"]);
            }else{
                setvalLeave(undefined);
                setallowLeave(false);
                message.error(`พบปัญหาเกี่ยวกับระบบ กรุณาติดต่อผู้ดูแล`);
            }
        })
        .catch(error => {

        });
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                fullscreen={isLoading}
                // indicator={<LoadingOutlined style={{ fontSize: "60px" }} />} 
                indicator={<div className="spin-loading-site" />}
            >
                <div className="layout-page">
                    <div id="body-containers" className="body-layout">
                        <div className="body-container-page">
                            <div className="panel-header" style={{height: '13dvh', padding: '0 30px', display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                <div><img style={{width: "50px"}} src={`${DOMAIN_URL}/images/svg/i24-logo.svg`} alt="logo"/></div>
                            </div>
                            <div className="panel-body" style={{alignItems: "center", height: "79dvh"}}>
                                <div style={{width: "100%", padding: '0 30px', maxHeight: '79dvh', overflowY: 'auto', boxShadow: allowLeave == true ? 'rgba(0, 0, 0, 0.3) 0px -20px 30px 0px inset' : 'none'}}>
                                    <div className="panel-txt-header">
                                        <div style={{color: "#fff", fontSize: 20, marginBottom: 10}}>{'แบบฟอร์มใบลา'}</div>
                                    </div>
                                    <div>
                                        <Form
                                            layout="vertical"
                                            form={form}
                                            onFinish={async (values: any) => {
                                                setLoading(true);

                                                // valLeave //data ประเภทการลา
                                                // leaveSub //data รูปแบบการลา
                                                // leaveMode //data ช่วงเวลา

                                                let leave_type: any = valLeave;
                                                let leave_option: any = leaveMode;
                                                let leave_detail: any = values?.leave_detail;
                                                let start_date: any;
                                                let end_date: any;

                                                if(leaveSub == "one"){
                                                    start_date = values?.date;
                                                    end_date = values?.date;
                                                }else if(leaveSub == "multi"){
                                                    start_date = values?.start_date;
                                                    end_date = values?.end_date;
                                                }

                                                setTimeout(() => {
                                                    let body: any = {
                                                        leave_type: leave_type,
                                                        start_date: start_date,
                                                        end_date: end_date,
                                                        leave_option: leave_option,
                                                        leave_detail: leave_detail,
                                                    }
                        
                                                    axiosInstance.post(`${API_URL}/api/leaves/createLeave`,body,{ headers: {Authorization: `Bearer ${TOKEN}`}})
                                                    .then((response) => {
                                                        push("/leave-work");
                                                        // successNoti();
                                                    })
                                                    .catch(error => {
                                                        console.error('error ',error);
                                                    })
                                                },500);
                                            }}
                                            requiredMark={false}
                                            initialValues={{
                                                remember: false,
                                            }}
                                        >
                                            <Form.Item
                                                name="leave_type"
                                                className="mb-30px"
                                                label={<span style={{color: "#fff"}}>{"ประเภทการลา"}</span>}
                                                rules={[{ required: true, message: "เลือกรูปแบบการลาด้วย ไอ้เวร" }]}
                                            >
                                                <Select 
                                                    placeholder={t("เลือก")}
                                                    options={leaveType}
                                                    allowClear
                                                    onChange={(e: any) => {
                                                        if(e !== undefined){
                                                            checkLeave(e);
                                                        }else{
                                                            setcanLeaveCount(0);
                                                            setallowLeave(false);
                                                            settk(!tk);
                                                            form.resetFields();
                                                        }}}
                                                    style={{height: 50}}
                                                    
                                                />
                                            </Form.Item>

                                            <div className="box_border_running blue" style={{borderRadius: 10}}>
                                                <div style={{color: "#fff", padding: "15px 10px", borderRadius: "5px", backdropFilter: "blur(5px)", background: '#2C2F48'}}>
                                                    <span>{'คุณเหลือจำนวนการลาอีก :'}</span>
                                                    <span style={{float: "right"}}>{canLeaveCount + "/ครั้ง ต่อปี"}</span>
                                                </div>
                                            </div>


                                            {allowLeave == true &&
                                                <div className="leave-panel" style={{marginBottom: 50, paddingTop: 10}}>

                                                    <Form.Item
                                                        name="leave_sub_option"
                                                        className="mb-30px"
                                                        label={<span style={{color: "#fff"}}>{"รูปแบบการลา"}</span>}
                                                    >
                                                        <Radio.Group 
                                                            onChange={(e: any) => {
                                                                let val: any = e.target.value;
                                                                setleaveSub(val);
                                                                if(val == "multi"){
                                                                    setleaveMode("Full");
                                                                    settk(!tk);
                                                                }

                                                                form.resetFields(["leave_option", "date", "start_date", "end_date"]); //clear_form_on_change
                                                            }}
                                                            defaultValue={"one"}
                                                        >
                                                            <Radio value={"one"}><span style={{color: "#fff"}}>{t("ลาวันเดียว")}</span></Radio>
                                                            <Radio value={"multi"}><span style={{color: "#fff"}}>{t("ลาหลายวัน")}</span></Radio>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    {leaveSub == "one" ?
                                                        <div className="leave-components">
                                                            <Form.Item
                                                                name="leave_option"
                                                                className="mb-30px"
                                                                label={<span style={{color: "#fff"}}>{"ช่วงเวลา"}</span>}
                                                                rules={[{ required: true, message: "เลือกรูปแบบการลาด้วย ไอ้เวร" }]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("เลือก")}
                                                                    options={[
                                                                        {
                                                                            label: "ลาเต็มวัน",
                                                                            value: "Full"
                                                                        },
                                                                        {
                                                                            label: "ลาช่วงเช้า",
                                                                            value: "Half Morning"
                                                                        },
                                                                        {
                                                                            label: "ลาช่วงบ่าย",
                                                                            value: "Half Afternoon"
                                                                        }
                                                                    ]}
                                                                    allowClear
                                                                    onChange={(e: any) => {if(e == undefined){setleaveMode("Full")}else{setleaveMode(e)}}}
                                                                    style={{height: 50}}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name="date"
                                                                className="mb-30px"
                                                                label={<span style={{color: "#fff"}}>{"วันที่"}</span>}
                                                                rules={[{ required: true, message: "แหกตาดูวันที่มึงจะเริ่มหยุดหน่อย" }]}
                                                            >
                                                                <DatePicker 
                                                                    onChange={(e)=> console.log(e)} 
                                                                    style={{width: "100%", height: 50}} 
                                                                    placeholder="เลือก"
                                                                    disabledDate={disabledDate}
                                                                    inputReadOnly={true}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        :
                                                        <div className="leave-components">
                                                            <Form.Item
                                                                name="start_date"
                                                                className="mb-30px"
                                                                label={<span style={{color: "#fff"}}>{"ลาวันที่"}</span>}
                                                                rules={[{ required: true, message: "แหกตาดูวันที่มึงจะเริ่มหยุดหน่อย" }]}
                                                            >
                                                                <DatePicker 
                                                                    onChange={(e)=> console.log(e)} 
                                                                    style={{width: "100%", height: 50}} 
                                                                    placeholder="เลือก"
                                                                    disabledDate={disabledDate}
                                                                    inputReadOnly={true}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name="end_date"
                                                                className="mb-30px"
                                                                label={<span style={{color: "#fff"}}>{"ถึงวันที่"}</span>}
                                                                rules={[{ required: true, message: "แหกตาดูวันที่มึงจะเริ่มหยุดหน่อย" }]}
                                                            >
                                                                <DatePicker 
                                                                    onChange={(e)=> console.log(e)} 
                                                                    style={{width: "100%", height: 50}} 
                                                                    placeholder="เลือก"
                                                                    disabledDate={disabledDate}
                                                                    inputReadOnly={true}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    }

                                                    <Form.Item
                                                        label={<span style={{color: "#fff"}}>{"เหตุผลในการลา อธิบายมาสัก 15บรรทัด"}</span>}
                                                        name="leave_detail"
                                                        style={{marginBottom: 15}}
                                                        rules={[{ required: true, message: "ก็ใส่เหตุผลไปสักหน่อย มันจะตายหรอไงไอน้อง" }]}
                                                    >
                                                        <TextArea style={{height: "120px", maxHeight: "170px", borderRadius: 5}} placeholder="เหตุผลในการลา"/>       
                                                    </Form.Item>

                                                    <Button onClick={() => form?.submit()} type="primary" style={{width: "100%", height: "50px", backgroundColor: '#46A2FF', boxShadow: "1px 0px 10px #5517ff", color: "#fff", border: 'none'}} disabled>{t("ส่งแบบฟอร์ม")}</Button>
                                                </div>
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}