import { IResourceComponentsProps, useCustom, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import { Button } from "antd"
import { CrownOutlined, RightOutlined, LeftOutlined, SolutionOutlined, StarOutlined, NotificationOutlined } from '@ant-design/icons';
import { useState } from "react";
import { Page_leaveadmin } from "./pages";

export const AdminPage: React.FC<IResourceComponentsProps> = () => {
    const [collape, setcollape] = useState<boolean>(false);
    const [page, setpage] = useState<any>('');

    const renderMenu: any = () => {
        let listMenu: any = [
            {id: 0, label:'รายการลา', path: 'leave', icon: <SolutionOutlined style={{color: "#ffb92c"}}/>},
            {id: 1, label:'แจกPoint', path: 'point', icon: <StarOutlined style={{color: "#ffb92c"}}/>},
            {id: 2, label:'ประกาศ', path: 'notification', icon: <NotificationOutlined style={{color: "#ffb92c"}}/>},
        ];

        return listMenu?.map((item: any, key: any) => {return(
            <div className={`box_border_running ${page == item?.path ? 'yellow':'none'}`} style={{borderRadius: 10, marginTop: key == 0 ? '-5px' : '5px', width: !collape ? 40 : '90%', transition: '0.3s'}}>
                <Button icon={item?.icon} type="primary" style={{width: !collape ? 40 : '100%', height: 40, background: '#1F3144', boxShadow: page == item?.path ? '0px 0px 5px #6EB7FF' : 'none', transition: '0.3s'}} onClick={()=> setpage(item?.path)}>
                    {collape && <span style={{transition: '0.3s'}}>{item?.label}</span>}
                </Button>
            </div>
        )})
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: !collape ? '15% 85%' : '30% 70%', height: '100%', transition: '0.5s'}}>
            <div style={{height: '100%', position: 'relative', boxShadow: '1px 0 10px rgba(0, 0, 0, 0.3)'}}>
                <div 
                    style={{
                        position: 'absolute', 
                        right: -15, 
                        backgroundColor: '#ffb92c', 
                        height: 25, 
                        width: 25, 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        borderRadius: 5, 
                        top: 5,
                        color: '#fff',
                        transition: '0.5s',
                        zIndex: 5,
                        cursor: 'pointer'
                    }}
                    onClick={()=> setcollape(!collape)}
                >
                    {!collape ? <RightOutlined />:<LeftOutlined />}
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start'}}>
                    <div style={{padding: '10px 5px', width: !collape ? 'auto' : '100%'}}>
                        {renderMenu()}
                    </div>
                </div>
            </div>
            <div style={{height: '100%', overflowY: 'auto'}}>
                    {page == 'leave' && <Page_leaveadmin/>}
            </div>
        </div>
    )
}