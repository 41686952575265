import { IResourceComponentsProps, useCustom, useGetIdentity, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
import { UploadProps } from "antd/es/upload";
import { Avatar, Badge, Button, Col, ConfigProvider, Divider, Form, QRCode, Row, Spin, Tag, Upload, message, Image } from "antd";
import { useRef, useState } from "react";
import { CameraOutlined, LoadingOutlined, UserOutlined, ApartmentOutlined, DeleteOutlined, DollarOutlined, ShopOutlined, LogoutOutlined, AuditOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { API_URL, DOMAIN_URL } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { MenuCustom } from "components/menu_footer";

export const ScoreboardPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { push } = useNavigation();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataScore, setdataScore] = useState<any>();
    const [dataComp, setdataComp] = useState<any>();
    const [point, setpoint] = useState<any>();

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;
                let compID: any = dataLoad?.user_profile?.companies[0]?.id
                setpoint(dataLoad?.user_profile?.point)

                if(compID){
                    setdataComp(dataLoad?.user_profile?.companies[0]);
                    await getScore(compID);
                    settk(!tk);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 5000);
            },
            onError(err: any){
                localStorage?.clear();
            }
        },
    });

    function getScore(compID: any){
        axiosInstance.get(`${API_URL}/api/user-point/find_point_by_company?id=${compID}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data;
            if(response?.length > 0){
                let filterDT: any = response?.filter((item: any) => item?.status !== "INACTIVE");
                setdataScore(filterDT);
            }else{
                setdataScore([]);
            }
            settk(!tk);
        })
        .catch(error => {

        });
    }

    const rendertopScore = (item: any) => {
        console.log(item)
        return console.log("first")
    }

    const renderScore = (item: any, number: any) => {
        if(number <= 3){
            return (
                <div className={`panel_border-line_score list-top-${number}`} style={{marginBottom: "10px", padding: "10px"}}>
                    <div>{number}</div>
                    <div><Avatar className="normal-avatar" src={item?.line_pictureUrl}/></div>
                    <div className="item-name">{item?.first_name + " " + item?.last_name}</div>
                    <div>{item?.point ? item?.point.toFixed(2) : 0}</div>
                </div>
            )
        }else{
            return (
                <div className="panel_border-line_score" style={{marginBottom: "10px", padding: "10px"}}>
                    <div>{number}</div>
                    <div><Avatar className="normal-avatar" src={item?.line_pictureUrl} icon={<UserOutlined />}/></div>
                    <div className="item-name">{item?.first_name + " " + item?.last_name}</div>
                    <div>{item?.point ? item?.point.toFixed(2) : 0}</div>
                </div>
            )
        }
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
                <Spin 
                    // className="spin-loading-score-board"
                    spinning={isLoading}
                    // indicator={<LoadingOutlined style={{ fontSize: "60px" }} />} 
                    // indicator={<div className="btn-skip-loading" onClick={() => setLoading(false)}>{"Skip"}</div>}
                    fullscreen={isLoading}
                    indicator={
                        <div className="spin-loading-score">
                            <div className="btn-skip-loading" onClick={() => setLoading(false)}>{"Skip"}</div>
                        </div>
                    }
                >
                    <div className="layout-page">
                        <div id="body-containers" className="body-layout">
                            <div className="body-container-page">
                                <div className="panel-header" style={{height: '13dvh', padding: '0 30px', display: 'grid', justifyContent: 'start', alignItems: 'center', gridTemplateColumns: '30% 70%'}}>
                                    <div><img style={{width: "50px"}} src={`${DOMAIN_URL}/images/svg/i24-logo.svg`} alt="logo"/></div>
                                    <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 10, height: '100%'}}>
                                        <div style={{width: "auto", height: "30px", backgroundColor: '#283754', marginTop: '-10px', color: '#fff', display: 'flex', alignItems: 'center', padding: '0px 15px', borderRadius: 5}}>{'My Point : ' + point?.toFixed(2)}</div>
                                    </div>
                                </div>
                                <div className="panel-body" style={{alignItems: "start", height: "79dvh"}}>
                                    <div style={{width: "100%", padding: '0 30px'}}>
                                        {/* <div className="panel-txt-header">
                                            <span><Tag color="#ff0000">{t("NEWS")}</Tag>{t("Board")}</span>
                                        </div> */}
                                        <div style={{textAlign: "center"}}>
                                            <span style={{color: "#fff", fontSize: "35px"}}>{"SCORE BOARD"}</span>
                                        </div>
                                        <div style={{textAlign: "center", marginBottom: "70px", marginTop: "-10px"}}>
                                            <span className="txt-name-comp">{dataComp ? dataComp?.company_name : "Unknow"}</span>
                                        </div>
                                        <div className="top-tier-score" style={{marginBottom: 15}}>
                                            <div className="panel-top top-2" style={{marginTop: "20px"}}>
                                                <div style={{marginTop: "20px"}}>
                                                    {/* <Avatar
                                                        className="focus_top_another"
                                                        size={60} 
                                                        shape="square" 
                                                        src={dataScore && dataScore?.length > 0 ? dataScore[1]?.line_pictureUrl : null}
                                                    /> */}
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <div className="box_border_running blue" style={{borderRadius: 500, padding: 1}}>
                                                            <Avatar
                                                                size={80} 
                                                                shape="circle" 
                                                                src={dataScore && dataScore?.length > 0 ? dataScore[1]?.line_pictureUrl : null}
                                                                style={{background: '#1F3144'}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'center', transform: 'translateY(-15px)'}}><div style={{width: 20, height: 20, background: '#18abff', textAlign: 'center', borderRadius: 50, fontWeight: '400', color: '#fff'}}>{2}</div></div>
                                                </div>
                                            </div>
                                            <div className="panel-top top-1">
                                                <div style={{marginTop: "-30px"}}>
                                                    <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                                                        <img src={'../images/img/crown.png'} width={60} height={30}/>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <div className="box_border_running yellow" style={{borderRadius: 500, padding: 5}}>
                                                            <Avatar
                                                                className="focus_top_1"
                                                                size={130} 
                                                                shape="circle" 
                                                                src={dataScore && dataScore?.length > 0 ? dataScore[0]?.line_pictureUrl : null}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'center', transform: 'translateY(-18px)'}}><div style={{width: 20, height: 20, background: '#ffb92c', textAlign: 'center', borderRadius: 50, fontWeight: 'bold', color: '#fff'}}>{1}</div></div>
                                                </div>
                                            </div>
                                            <div className="panel-top top-3" style={{marginTop: "20px"}}>
                                                <div style={{marginTop: "20px"}}>
                                                    {/* <Avatar
                                                        className="focus_top_another"
                                                        size={60} 
                                                        shape="square" 
                                                        src={dataScore && dataScore?.length > 0 ? dataScore[2]?.line_pictureUrl : null} 
                                                    /> */}
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <div className="box_border_running blue" style={{borderRadius: 500, padding: 1}}>
                                                            <Avatar
                                                                size={80} 
                                                                shape="circle" 
                                                                src={dataScore && dataScore?.length > 0 ? dataScore[2]?.line_pictureUrl : null}
                                                                style={{background: '#1F3144'}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'center', transform: 'translateY(-15px)'}}><div style={{width: 20, height: 20, background: '#c718ff', textAlign: 'center', borderRadius: 50, fontWeight: '400', color: '#fff'}}>{3}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll_score">
                                            {dataScore && dataScore?.length > 0 && dataScore?.map((item: any, key: any) => {
                                                if(key >= 3){
                                                    return (
                                                        <div>
                                                            {renderScore(item, key + 1)}
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-menu"><MenuCustom/></div>
                        </div>
                    </div>
                </Spin>
        </ConfigProvider>
    )
}