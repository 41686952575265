import { IResourceComponentsProps, useCustom, useLogout, useTranslate } from "@refinedev/core"
import { Spin, ConfigProvider, Table, Tag, DatePicker, Row, Col, Select, Form } from "antd";
import { API_URL, DOMAIN_URL } from "../../constants";
import { useState } from "react";
import { axiosInstance } from "@refinedev/strapi-v4";
import dayjs from "dayjs";
import { HistoryOutlined } from '@ant-design/icons';
import { MenuCustom } from "components/menu_footer";

let startWork: any = 2023;
let nowYear: any = dayjs(new Date)?.format("YYYY");
let resultWork: any = Number(nowYear) - Number(startWork);

export const HistoryPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const [formSearch] = Form.useForm<any>();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [dataTB, setdataTB] = useState<any>([]);
    const [dataTBL, setdataTBL] = useState<any>([]);
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isLoadingTB, setisLoadingTB] = useState<boolean>(false);
    const { mutate: mutateLogout } = useLogout();

    const [pageSize, setPageSize] = useState<any>(5);
    const [optionMonth, setoptionMonth] = useState<any>([]);
    const [optionYear, setoptionYear] = useState<any>([]);

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                settk(!tk);
                // await getTheme(dataLoad?.user_profile?.current_theme || "styles-main");
                // await getFilter();
                await getOptions();
                setTimeout(() => {
                    getHistory();
                }, 300);

            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    function getHistory(){
        axiosInstance.get(`${API_URL}/api/attendances/user_history`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            let respon: any = res?.data;
            let filterdt: any = [];

            for (let index = 0; index <= resultWork; index++) {
                let fiteminYear: any = respon?.filter((item: any) => dayjs(item?.createdAt).format("YYYY") == startWork + index);
                filterdt.push(...fiteminYear.reverse());
            }

            let reversedt: any = filterdt?.reverse();
            setdataTB((pre: any) => reversedt);
            setdataTBL((pre: any) => reversedt);
            settk(!tk);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    async function getOptions(){
        let startYear: any = 2023;
        let thisYear: any = dayjs(new Date)?.format("YYYY");
        let step: boolean = false;
        let itemYear: any = [];

        for (let index = 0; step == false; index++) {
            let plusYear: any = startYear + index; 
            itemYear.push({id: index, label: String(plusYear), value: String(plusYear)})
            if(thisYear == plusYear.toString()){step = true}
        }

        let itemMonth: any = [
            {id: 0,label: "January",label_th: "มกราคม", value: 1},
            {id: 1,label: "February",label_th: "กุมภาพันธ์", value: 2},
            {id: 2,label: "March",label_th: "มีนาคม", value: 3},
            {id: 3,label: "April",label_th: "เมษายน", value: 4},
            {id: 4,label: "May",label_th: "พฤษภาคม", value: 5},
            {id: 5,label: "June",label_th: "มิถุนายน", value: 6},
            {id: 6,label: "July",label_th: "กรกฎาคม", value: 7},
            {id: 7,label: "August",label_th: "สิงหาคม", value: 8},
            {id: 8,label: "September",label_th: "กันยายน", value: 9},
            {id: 9,label: "October",label_th: "ตุลาคม", value: 10},
            {id: 10,label: "November",label_th: "พฤศจิกายน", value: 11},
            {id: 11,label: "December",label_th: "ธันวาคม", value: 12}
        ]

        setoptionYear((pre: any) => itemYear);
        setoptionMonth((pre: any) => itemMonth);
        settk(!tk);
    }

    async function getTheme(value: any){
        let theme: any = value;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${DOMAIN_URL}/theme/${theme}.css`;
        document.head.appendChild(link);
    }

    const columns: any = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            sorter: (a:any, b:any) => (a?.time_date ? a?.time_date: "").localeCompare(b?.time_date ? b?.time_date: ""),
            render: (value:any, record:any) => {
                // return (<span>{dayjs(record?.createdAt)?.format("DD/MM/YYYY")}</span>)
                return (
                    <div style={{backgroundColor: "#2C2F48", padding: 7, height: 53, borderRadius: "10px 0 0 10px"}}>
                        <div style={{backgroundColor: "#323154", width: 65, padding: '5px 0', borderRadius: 5, border: '1px solid #0056ad'}}>
                            <div style={{color: "#6EB7FF", fontSize: 16, textAlign: "center"}}>
                                {dayjs(record?.createdAt)?.format("DD")}
                            </div>
                            <div style={{color: "#fff", fontSize: 12, textAlign: "center"}}>
                                {dayjs(record?.createdAt)?.format("MM/YYYY")}
                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            title: <div style={{marginRight: "30px"}}>{'Clock In'}</div>,
            dataIndex: 'time_in',
            key: 'time_in',
            width: "auto",
            sorter: (a:any, b:any) => (a?.time_in ? a?.time_in: "").localeCompare(b?.time_in ? b?.time_in: ""),
            render: (value:any, record:any) => {
                // return (<span>{record?.time_in ? record?.time_in : "-"}</span>)
                return (
                    <div style={{backgroundColor: "#2C2F48", padding: 7, height: 53, display: "flex", justifyContent: "start", alignItems: "center", gap: 10}}>
                        {record?.time_in ? <><HistoryOutlined style={{marginTop: '-3px', color: "#80E57B"}}/><div style={{color: "#fff"}}>{record?.time_in ? record?.time_in : "-"}</div></> : <div style={{color: "#fff"}}>{"-"}</div>}
                    </div>
                )
            },
        },
        {
            title: <div style={{marginRight: "30px"}}>{'Clock Out'}</div>,
            dataIndex: 'time_out',
            key: 'time_out',
            width: "auto",
            sorter: (a:any, b:any) => (a?.time_out ? a?.time_out: "").localeCompare(b?.time_out ? b?.time_out: ""),
            render: (value:any, record:any) => {
                // return (<span>{record?.time_out ? record?.time_out : "-"}</span>)
                return (
                    <div style={{backgroundColor: "#2C2F48", padding: 7, height: 53, display: "flex", justifyContent: "start", alignItems: "center", borderRadius: "0 10px 10px 0", gap: 10}}>
                        {record?.time_out ? <><HistoryOutlined style={{marginTop: '-3px', color: "#FE7B7A"}}/><div style={{color: "#fff"}}>{record?.time_out ? record?.time_out : "-"}</div></> : <div style={{color: "#fff"}}>{"-"}</div>}
                    </div>
                )
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onSearch(){
        setisLoadingTB(true);
        let dataLoad:any = dataTBL;
        let values: any = formSearch?.getFieldsValue();
        let month: any = values?.selected_month && values?.selected_month !== "" ? values?.selected_month : undefined;
        let year: any = values?.selected_year && values?.selected_year !== "" ? values?.selected_year : undefined;

        let newDT: any = [];
        newDT = dataLoad?.filter((e:any) => 
            (month ? dayjs(e.createdAt)?.format('MM') == month : e?.createdAt !== undefined) &&
            (year ? dayjs(e.createdAt)?.format('YYYY') == year : e?.createdAt !== undefined)
        );

        setdataTB((pre: any) => newDT);
        settk(!tk);
        setTimeout(() => {
            setisLoadingTB(false);
        }, 300);
    }
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
                components: {
                    Table: {
                        colorBgContainer: undefined,
                    },
                },
            }}
        >
            <Spin 
                spinning={isLoading} 
                indicator={<div className="spin-loading-site" />}
                fullscreen={isLoading}
            >
                <div className="layout-page">
                    <div id="body-containers" className="body-layout">
                        <div className="body-container-page">
                            <div className="panel-header" style={{height: '13dvh', padding: '0 30px', display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                <div><img style={{width: "50px"}} src={`${DOMAIN_URL}/images/svg/i24-logo.svg`} alt="logo"/></div>
                            </div>
                            <div className="panel-body" style={{alignItems: "center", height: "79dvh"}}>
                                <div style={{width: "100%", padding: '0 30px'}}>
                                    <div className="panel-txt-header">
                                        <div style={{color: "#fff", fontSize: 20, marginBottom: 10}}>{'ประวัติการลงเวลา'}</div>
                                    </div>
                                    <div style={{marginBottom: "10px"}}>
                                        <Form
                                            layout="vertical"
                                            form={formSearch}
                                        >
                                            <Row gutter={12}>
                                                <Col span={14} className="gutter-row">
                                                    <Form.Item 
                                                        label=" "
                                                        name="selected_month"
                                                        style={{marginBottom: 0, width: "100%"}}
                                                        className="form-non-txt"
                                                    >
                                                        <Select 
                                                            allowClear 
                                                            size="large" 
                                                            style={{width: "100%"}} 
                                                            placeholder="Month"
                                                            options={optionMonth}
                                                            onChange={onSearch}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10} className="gutter-row">

                                                    <Form.Item 
                                                        label=" "
                                                        name="selected_year"
                                                        style={{marginBottom: 0, width: "100%"}}
                                                        className="form-non-txt"
                                                    >
                                                        <Select 
                                                            allowClear 
                                                            size="large" 
                                                            style={{width: "100%"}} 
                                                            placeholder="Year"
                                                            options={optionYear}
                                                            onChange={onSearch}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                    <div style={{marginBottom: 40}}>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                Table: {},
                                                },
                                            }}
                                        >
                                            <Table 
                                                dataSource={dataTB ? dataTB : []} 
                                                columns={columns}
                                                loading={isLoadingTB}
                                                // summary={5}
                                                pagination={{
                                                    size: "small",
                                                    pageSize: pageSize,
                                                    onChange: handlePageSizeChange,
                                                    showSizeChanger: false, 
                                                }}
                                                style={{width: '100%'}}
                                            />
                                        </ConfigProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}