import { IResourceComponentsProps, useCustom, useGetIdentity, useLogout, useNavigation, useTranslate } from "@refinedev/core"
import "./styles.css";
import { UploadProps } from "antd/es/upload";
import { Avatar, Badge, Button, Col, ConfigProvider, Divider, Form, QRCode, Row, Spin, Tag, Upload, message } from "antd";
import { useRef, useState } from "react";
import { CameraOutlined, LoadingOutlined, UserOutlined, ApartmentOutlined, DeleteOutlined, DollarOutlined, ShopOutlined, LogoutOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { API_URL, DOMAIN_URL } from "../../constants";
import { axiosInstance } from "@refinedev/strapi-v4";
import { MenuCustom } from "components/menu_footer";

export const VoteStarPage: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { push } = useNavigation();
    const TOKEN: any = localStorage.getItem("AUTH");
    const [tk, settk] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataUser, setdataUser] = useState<any>();
    const [dataComp, setdataComp] = useState<any>();
    const [voteStep, setvoteStep] = useState<any>(1);
    const [keySelected, setkeySelected] = useState<any>();

    const { data: apiUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;
                let compID: any = dataLoad?.user_profile?.companies[0]?.id

                if(compID){
                    setdataComp(dataLoad?.user_profile?.companies[0]);
                    await getanotherUser(compID);
                    settk(!tk);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 5000);
            },
            onError(err: any){
                localStorage?.clear();
            }
        },
    });

    function getanotherUser(compID: any){
        axiosInstance.get(`${API_URL}/api/user-profile/getAll?id=${compID}`,
            { headers: { Authorization: `Bearer ${TOKEN}` } }
        )
        .then((res) => {
            let response: any = res?.data;
            if(response?.length > 0){
                let filterDT: any = response?.filter((item: any) => item?.position?.pos_code !== "NXAD001" && item?.position?.pos_code !== "GM001");
                setdataUser(filterDT);
                settk(!tk);
            }else{
                setdataUser([]);
            }
            settk(!tk);
        })
        .catch(error => {

        });
    }

    const RenderUser: any = ({data}: any) => {
        return data?.map((item: any, key: any) => {
            console.log(item)
            return (
                <div className={`card-user-panel ${keySelected == key && `card-user-panel-selected`}`}
                    style={{
                        backgroundImage: `url(${item?.line_pictureUrl ? item?.line_pictureUrl : null})`, 
                        backgroundPosition: "center", 
                        backgroundSize: "cover",
                    }}
                    onClick={() => setkeySelected(key)}
                >
                    <div className="card-user-vote">
                        <div>
                            <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                                <Avatar 
                                    className="avatar-user-detail" 
                                    src={item?.line_pictureUrl} 
                                    size={100} 
                                    shape="square"
                                    icon={<UserOutlined/>}
                                />
                            </div>
                            <div className="card-user-detail">
                                <div className="label-detail">{"First name"}</div>
                                <div className="value-detail">{item?.first_name}</div>
                                <div className="label-detail">{"Last name"}</div>
                                <div className="value-detail">{item?.last_name}</div>
                                <div className="label-detail">{"Position"}</div>
                                <div className="value-detail">{item?.position?.pos_name}</div>
                            </div>
                            {/* <div>
                                <span style={{marginRight: "5px"}}>{"First name :"}</span>
                                <span>{item?.first_name}</span>
                            </div>
                            <div>
                                <span style={{marginRight: "5px"}}>{"Last name :"}</span>
                                <span>{item?.last_name}</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            )
        })
    }



    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgMask: "rgba(27, 26, 24, 1)",
                    colorPrimary: "#f07d51",
                },
            }}
        >
            <Spin 
                spinning={isLoading}
                fullscreen={isLoading}
                indicator={
                    <div className="spin-loading-score">
                        <div className="btn-skip-loading" onClick={() => setLoading(false)}>{"Skip"}</div>
                    </div>
                }
            >
                <div className="layout-site">
                    <div className="body-container-site">
                        <div className="panel-header">
                            <div className="font-header">
                                <span className="main">{t("i24")}</span>
                                <span className="sub-main">{t("HOURS")}</span>
                            </div>
                        </div>
                        <div className="panel-body panel-full-h">
                            <div style={{width: "90%"}}>
                                {/* <div className="panel-txt-header">
                                    <span><Tag color="#ff0000">{t("NEWS")}</Tag>{t("Board")}</span>
                                </div> */}
                                <div style={{textAlign: "center"}}>
                                    <span style={{color: "#fff", fontSize: "35px"}}>{"VOTE STAR"}</span>
                                </div>
                                <div style={{textAlign: "center", marginTop: "-15px"}}>
                                    <span style={{color: "#fff", fontSize: "16px", opacity: "0.5"}}>{"OF MONTH"}</span>
                                </div>
                                <div style={{textAlign: "center", marginBottom: "20px", marginTop: "-10px"}}>
                                    <span className="txt-name-comp">{dataComp ? dataComp?.company_name : "Unknow"}</span>
                                </div>
                                <div style={{textAlign: "center", marginBottom: "20px"}}>
                                    <span className="txt-name-comp" style={{fontSize: "32px", fontStyle: "italic"}}>{"TOP: " + voteStep}</span>
                                </div>
                                <div className="panel-user-list">
                                    <div className="in-panel-user-list">
                                        {dataUser && dataUser?.length > 0 && <RenderUser data={dataUser}/>}
                                    </div>
                                </div>
                                <div>
                                    VOTE: XXXXXXXX
                                </div>
                                <div>
                                    <Button className="btn-vote">Vote</Button>
                                </div>
                            </div>
                        </div>
                        <div className="panel-menu"><MenuCustom/></div>
                    </div>
                </div>
            </Spin>
        </ConfigProvider>
    )
}