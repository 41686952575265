import { IResourceComponentsProps } from "@refinedev/core"
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import "./styles.css"
import { useState } from "react";

export const ComponentTest: React.FC<IResourceComponentsProps> = () => {

    const [toggle, settoggle] = useState<boolean>(true);

    const bodyStyles: React.CSSProperties = {
        background: "linear-gradient(0deg, rgba(6,55,117,1) 0%, rgba(21,107,215,1) 100%)",
        width: "100%",
        height: "100px",
        position: "relative",
        transition: "0.3s"
    };

    const componentStyles: React.CSSProperties = {
        background: "#dedede",
        width: "100%",
        height: toggle ? "5px" : "300px",
        position: "relative",
        transition: "0.3s",
        marginTop: "30px",
        opacity: toggle ? "0%" : "100%",
    };

    return (
        <div>
            <div style={bodyStyles}>
                <div className="indicator">
                    <div className="icon-flag" onClick={() => settoggle(!toggle)}>
                        {toggle ? <DownOutlined /> : <UpOutlined />}
                    </div>
                </div>
            </div>
            <div style={componentStyles}>

            </div>
        </div>
    )
}
