import { IResourceComponentsProps, useCustom, useLogout } from "@refinedev/core"
import { axiosInstance } from "@refinedev/strapi-v4";
import { API_URL } from "../../../constants";
import { useState } from "react";
import { Spin } from "antd";

export const Page_leaveadmin: React.FC<IResourceComponentsProps> = () => {

    const TOKEN: any = localStorage.getItem("AUTH");
    const { mutate: mutateLogout } = useLogout();

    const [tk, settk] = useState<boolean>(true);
    const [dataLeavelist, setdataLeavelist] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    const { data: dataUser, refetch: refetchUser} = useCustom<any>({
        url: `${API_URL}/api/user-profile/find_by_user`,
        method: "get",
        config:{
            headers: { Authorization: `Bearer ${TOKEN}` },
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;

                settk(!tk);
                await getData(dataLoad?.company?.id);
            },
            onError(err: any){
                localStorage?.clear();
                mutateLogout();
            }
        },
    });

    function getData(compID: any){
        axiosInstance.get(`${API_URL}/api/leaves/findAllLeaveByComp?id=${compID}`, { headers: { Authorization: `Bearer ${TOKEN}` } })
        .then((res) => {
            let respon: any = res?.data;
            setdataLeavelist((pre: any)=> respon);
            settk(!tk);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch(error => {
            console.error('error ',error);
        });
    }

    const renderList: any = (data: any) => {
        return (data?.length > 0 ? 
            data?.map((item: any, key: any) => {
                let leaveOption: any = item?.leave_option? item?.leave_option == 'Full' ? "ลาเต็มวัน":item?.leave_option == 'Half Morning' ? "ลาช่วงเช้า":item?.leave_option == 'Half Afternoon' && "ลาช่วงบ่าย":null;
                console.log('item', item)
                return(
                    <div 
                        style={{
                            background: '#fff', 
                            marginBottom: key !== data?.length -1 ? '5px':'0',
                            padding: 5,
                            borderRadius: 5,
                            color: '#676767'
                        }}
                    >
                        <div>{item?.leave_type ? item?.leave_type?.leave_type_name : '-'}</div>
                        <div>{item?.user_profile ? item?.user_profile?.first_name + " " + item?.user_profile?.last_name : '-'}</div>
                        <div>{"เนื้อหา : "} + {item?.leave_detail ? item?.leave_detail : '-'}</div>
                        <div>{leaveOption}</div>
                    </div>
                )
            })    
        :null)
    }

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <div style={{padding: '40px 20px 0 20px', height: '95%'}}>
                <div style={{color: '#fff', fontSize: 18}}>{'รายการลา'}</div>
                <div style={{color: '#fff', fontSize: 13, opacity: '0.6'}}>{'list รายการลาภายในบริษัท'}</div>
                <Spin spinning={isLoading} 
                >
                    <div style={{paddingTop: 20}}>{!isLoading && renderList(dataLeavelist)}</div>
                </Spin>
            </div>
        </div>
    )
}